import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import CourseDoulos from '../components/course/courseDoulos';

const Doulos = () => {
  return (
    <>
      <SEO title="Doulos" />
      <Layout>
        <CourseDoulos />
      </Layout>
    </>
  );
};

export default Doulos;
