import React from 'react';
import { Row, Col } from 'antd';

import Styles from './course-ind.module.css';
import HeroDoulos from '../image-components/hero/heroDoulos';
import TabAbout from '../image-components/tab/tabAbout';
import TabOverview from '../image-components/tab/tabOverview';
import TabGuide from '../image-components/tab/tabGuide';

import GuideImg from '../../images/guide-doulos.jpeg';

const CourseDoulos = () => {
  return (
    <>
      <HeroDoulos />
      <Row className={Styles.row}>
        <Col className={Styles.col_left} sm={24} md={16}>
          <div className={Styles.heading}>DOULOS ADVANCE</div>
          <div className={Styles.body}>
            <div className={Styles.subtitle}>
              <div>Loving the World via the Love of God.</div>
              <p>
                Doulos Advance invites us into the biblical framework of
                servanthood and missions, as well as the role of the church in
                pursuing the renewal of all things. Doulos Advance is an
                invitation to become more like Christ who poured out his life in
                service to the God who calls us to love him through our
                neighbor. It is also an invitation to live generously and
                fulfill one of our highest callings to be a servant.
              </p>
            </div>
          </div>
          <div className={Styles.body}>
            <div className={Styles.quote}>
              "The only true and enduring motivation for the ministry of mercy
              (missions) is an experience and a grasp of the grace of God in the
              gospel. If we know we are sinners saved by grace alone, we will be
              both open and generous to the outcasts and the unlovely."
            </div>
            <div className={Styles.author}>TIM KELLER</div>
          </div>
        </Col>
        <Col className={Styles.col_right} sm={24} md={8}>
          <TabAbout>
            <div className={Styles.modal_title}>ABOUT</div>
            <div className={Styles.modal_body}>
              <h2 className={Styles.modal_subtitle}>The Learning Outcomes</h2>
              <p className={Styles.subheading}>Advance participants will:</p>
              <ol>
                <li>
                  Understand Ekko’s Missiology and core principles for mission.
                </li>
                <li>Have a biblical framework for mission and servanthood.</li>
                <li>Participate tangibly in the renewal of all things.</li>
              </ol>
              <h2 className={Styles.modal_subtitle}>The Big Idea</h2>
              <p className={Styles.subheading}>Cohort Based</p>
              <p>
                Ekko's Doulos Advance organizes participants to journey together
                through the entire program. In the duration of six weeks,
                participants’ primary learning and practice come from their
                willingness to engage with Scripture and tangibly participate in
                the work of renewal. In so doing, they learn largely from each
                other and stories and principles from Scripture.
              </p>
              <p className={Styles.subheading}>Advisor-Directed</p>
              <p>
                Doulos Advance is paired with a guide (Isaac Chong) and an
                assistant who will facilitate the weekly meetings.
              </p>
              <p className={Styles.subheading}>Online & Hybrid</p>
              <p>
                Doulos Advance engages in a variety of venues that are
                EPIC—experiential, participatory, image-driven and connective.
                It does not encourage simply downloading information; instead,
                participants and advisors value relationship, the dynamic
                interaction, and experimentation, which will enrich and deepen
                its outcome. The format comprise of weekly discussions online
                and weekly face-to-face meetings with group members.
              </p>
            </div>
          </TabAbout>
          <TabOverview>
            <div className={Styles.modal_title}>OVERVIEW</div>
            <div className={Styles.modal_body}>
              <div className={Styles.modal_paragraph}>
                <a
                  href="https://ekkochurch.com/wp-content/uploads/Advance/Doulos/Doulos%20Advance%20Syllabus%20S2021.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className={Styles.btn_syllabus}>Syllabus</button>
                </a>
                <div className={Styles.modal_subtitle}>Week 1</div>
                <div className={Styles.subheading}>Representatives of God</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 2</div>
                <div className={Styles.subheading}>
                  The New Egypt & The Stories We Tell
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 3</div>
                <div className={Styles.subheading}>Abrahamic Blessing</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 4</div>
                <div className={Styles.subheading}>
                  Prophetic Justice (Elijah Sung)
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 5</div>
                <div className={Styles.subheading}>
                  Jerusalem, Judea, Samaria, and the Ends of the Earth
                </div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Week 6</div>
                <div className={Styles.subheading}>The Garden & The City</div>
              </div>
              <div className={Styles.modal_paragraph}>
                <div className={Styles.modal_subtitle}>Mission Trip</div>
                <div className={Styles.subheading}>
                  Home Building in Ensenada
                </div>
              </div>
            </div>
          </TabOverview>
          <TabGuide>
            <div className={Styles.modal_title}>GUIDE</div>
            <div className={Styles.modal_body}>
              <img
                className={Styles.guide_img}
                src={GuideImg}
                alt="Pastor Isaac"
              />
              <div className={Styles.guide_name}>Isaac Chong</div>
              <div className={Styles.guide_subheading}>
                <div>Pastor of Ekko Church & Operations Director</div>
                <div>Doulos Advance Guide</div>
              </div>
              <p>
                Since the age of 21, when he had a supernatural encounter with
                the Holy Spirit, Isaac has been passionate about the gifts of
                the Spirit, the manifest presence of God, and the participation
                of the local church in the renewal of all things. He has a
                Bachelor in Economics, concentrating on third-world development,
                from UC Santa Cruz, a Master of Divinity from Fuller Theological
                Seminary, and a certificate in Church Administration from North
                Park University in Chicago. He is married to the love of his
                life, Grace, and they have two children: Hope and Zachary.
              </p>
            </div>
          </TabGuide>
        </Col>
      </Row>
    </>
  );
};

export default CourseDoulos;
